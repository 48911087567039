import { REGIONS } from '@/services/constants';

import { createUpsell } from '../upsell';

export const yeLWMM = createUpsell({
  id: 'yeLWMM',
  desc: `Upsell sequence with 12-month offer variant 1`,
  regions: [REGIONS.eu],
  seq: ['upsell-12-month', 'upsell-sweet-treats', 'upsell-detox', 'upsell-fast-food', 'upsell-alcohol'],
});
