import { PRICING_MODELS, QUIZ_PRODUCT_TYPES, REGIONS } from '@/services/constants';

import * as upsells from '../domain/sequences';

export const getUpsellSequence = ({ productType, pricingModel, region }) => {
  if (!region) {
    return upsells.K7m6Xc.sequence;
  }

  const hasProductInfo = !!productType && !!pricingModel;

  if (hasProductInfo && shouldUseFitnessPilatesUpsells({ productType, pricingModel, region })) {
    return upsells.oEX9H0.sequence;
  }

  if (shouldOfferCollagenUpsell(region)) {
    return upsells.YrhVjZ.sequence;
  }

  if (shouldOffer12MonthUpsellVariant(region)) {
    return upsells.yeLWMM.sequence;
  }

  if (shouldOffer12MonthUpsell(region)) {
    return upsells.jdz8pH.sequence;
  }

  return upsells.K7m6Xc.sequence;
};

const shouldUseFitnessPilatesUpsells = ({ productType, pricingModel, region }) => {
  return (
    region === REGIONS.us && productType === QUIZ_PRODUCT_TYPES.fitnessPilates && pricingModel === PRICING_MODELS.weekly
  );
};

const shouldOffer12MonthUpsell = (region) => {
  return upsells.jdz8pH.regions.includes(region);
};

const shouldOffer12MonthUpsellVariant = (region) => {
  return upsells.yeLWMM.regions.includes(region);
};

const shouldOfferCollagenUpsell = (region) => {
  return upsells.YrhVjZ.regions.includes(region);
};
