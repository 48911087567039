// import dummyOfferTest from './dummy-offer-test';
// import dummyTourTest from './dummy-tour-test';
import pilates28DayChallengeCheckoutTestDifferentTitle from './pilates-28-day-challenge-checkout-test-different-title';
import pilates28DayChallengeMetaChanges from './pilates-28-day-challenge-meta-changes';
import pilates28DayChallengeUpdatedTargedZoneStep from './pilates-28-day-challenge-updated-targed-zone-step';
import pilatesChallengeAndNutritionPriceIncrease from './pilates-challenge-and-nutrition-price-increase';
import rhNutritionQuizCheckoutCopyChangesMetaIssue from './rh-nutrition-quiz-checkout-copy-changes-meta-issue';
import yoga28DayChallengeFullBodyBeforeAfterImages from './yoga-28-day-challenge-full-body-before-after-images';
import yoga28DayChallengeMetaChanges from './yoga-28-day-challenge-meta-changes';

const tests = [
  // Dummy experiments
  // dummyOfferTest,
  // dummyTourTest,

  yoga28DayChallengeFullBodyBeforeAfterImages,
  pilates28DayChallengeCheckoutTestDifferentTitle,
  pilates28DayChallengeUpdatedTargedZoneStep,
  pilates28DayChallengeMetaChanges,
  yoga28DayChallengeMetaChanges,
  rhNutritionQuizCheckoutCopyChangesMetaIssue,
  pilatesChallengeAndNutritionPriceIncrease,
];

export default tests;
