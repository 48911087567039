
import { REGIONS } from '@/services/constants';

import { fetchContent, getFetchUrl, parseHTML } from './content-modal.service';

export default {
  name: 'ContentModal',
  props: {
    modalName: {
      type: String,
      required: true,
    },
    modalType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contentText: '',
    };
  },
  computed: {
    fetchUrl() {
      const locale = this.getLanguage || '';
      return getFetchUrl({
        type: this.modalType,
        locale,
        isEuOrUk: this.isEuOrUk,
        quizVariant: this.getQuizVariant,
      });
    },
    isEuOrUk() {
      return [REGIONS.eu, REGIONS.uk].includes(this.$countryCode);
    },
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    hideModalHandler() {
      this.$modal.hide(this.modalName);
    },

    async fetchContent() {
      try {
        const html = await fetchContent(this.fetchUrl);
        this.contentText = parseHTML(html);
      } catch (error) {
        this.$sentry.captureException(error);
      }
    },
  },
};
