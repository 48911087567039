import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_META_CHANGES,
  includedRoutes: [
    '/tour/pilates-28-day-challenge',
    '/tour/pilates-28-day-challenge/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
  ],
  variants: [
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT}`,
      expVariant: null,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_META_CHANGES,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_META_CHANGES}`,
      expVariant: '5141-1',
    },
  ],
};
