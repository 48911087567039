import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants.js';

export default {
  name: EXPERIMENT_NAMES.RH_NUTRITION_QUIZ_CHECKOUT_COPY_CHANGES_META_ISSUE,
  includedRoutes: [
    '/tour/nutrition-monthly-start',
    '/tour/nutrition-monthly-start/',
    '/quiz/authority-related-testimonials-body-nutrition',
    '/quiz/authority-related-testimonials-body-nutrition/',
  ],
  variants: [
    {
      tour: QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      path: `/tour/${QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY}`,
      expVariant: null,
    },
    {
      tour: QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES,
      path: `/tour/${QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES}`,
      expVariant: '5147-1',
    },
  ],
};
