import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_CHALLENGE_AND_NUTRITION_PRICE_INCREASE,
  includedRoutes: [
    '/quiz/enter-email-new/',
    '/quiz/enter-email-new',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
    '/quiz/authority-related-testimonials-body-nutrition/',
    '/quiz/authority-related-testimonials-body-nutrition',
  ],
  allocateOnClient: true,
  variants: [
    {
      pricing: 0,
    },
    {
      pricing: 1,
    },
    {
      pricing: 2,
    },
  ],
};
