import Vue from 'vue';

import { QUIZ_VARIANTS } from '@/services/constants';

import * as actions from './actions';
import * as getters from './getters';

import {
  SET_DEFAULT_STATE_VALUE_LANDING,
  SET_HEADER_STATUS,
  SET_INTERCOM_ID,
  SET_LANDING_PAGE_ID,
  SET_LEAD_USER_REFERRER_LINK,
  SET_PRODUCT,
  SET_PRODUCT_TYPE,
  SET_QUIZ_ANSWERS,
  SET_QUIZ_INDEX,
  SET_QUIZ_LIST,
  SET_QUIZ_REFERRER_LINK,
  SET_QUIZ_STEP_FROM,
  SET_QUIZ_STEP_TO,
  SET_QUIZ_TOTAL_LENGTH,
  SET_QUIZ_VARIANT,
  SET_TARGET_LOSS,
  SET_UNITS,
  SET_USER_ATTRS_EMAIL,
  SET_USER_ATTRS_FINAL_WEIGHT,
} from '../mutation-types';

const mutations = {
  [SET_QUIZ_LIST](state, payload) {
    state.quizList = payload;
  },
  [SET_HEADER_STATUS](state, payload) {
    state.headerStatus = payload;
  },
  [SET_QUIZ_TOTAL_LENGTH](state, payload) {
    state.totalLength = payload;
  },
  [SET_QUIZ_INDEX](state, payload) {
    state.currentIndex = payload;
  },
  [SET_QUIZ_ANSWERS](state, payload) {
    const index = state.answers.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      Vue.set(state.answers, index, payload);
    } else {
      state.answers.push(payload);
    }
  },
  [SET_USER_ATTRS_EMAIL](state, payload) {
    state.userAttrs.email = payload;
  },
  [SET_USER_ATTRS_FINAL_WEIGHT](state, payload) {
    state.userAttrs.finalWeight = payload;
  },
  [SET_INTERCOM_ID](state, payload) {
    state.intercomId = payload;
  },
  [SET_TARGET_LOSS](state, payload) {
    state.targetLoss = payload;
  },
  [SET_QUIZ_VARIANT](state, payload) {
    state.quizVariant = payload;
  },
  [SET_LANDING_PAGE_ID](state, payload) {
    state.landingPageId = payload;
  },
  [SET_QUIZ_REFERRER_LINK](state, payload) {
    state.quizReferrerLink = payload;
  },
  [SET_LEAD_USER_REFERRER_LINK](state, payload) {
    state.leadUserReferrerLink = payload;
  },
  [SET_UNITS](state, units) {
    state.units = units;
  },
  [SET_QUIZ_STEP_FROM](state, payload) {
    state.quizStepFrom = payload;
  },
  [SET_QUIZ_STEP_TO](state, payload) {
    state.quizStepTo = payload;
  },
  [SET_PRODUCT_TYPE](state, payload) {
    state.productType = payload;
  },
  [SET_PRODUCT](state, payload) {
    state.product = payload;
  },
  [SET_DEFAULT_STATE_VALUE_LANDING](state, payload) {
    state.defaultStateValue = payload;
  },
};

const state = () => ({
  intercomId: null,
  quizReferrerLink: null,
  leadUserReferrerLink: null,
  quizList: [],
  quizVariant: QUIZ_VARIANTS.DEFAULT,
  landingPageId: 0,
  currentIndex: 0,
  totalLength: 0,
  answers: [],
  headerStatus: true,
  userAttrs: {
    email: '',
    finalWeight: '',
  },
  targetLoss: 0,
  units: null,
  quizStepFrom: '',
  quizStepTo: '',
  defaultStateValue: '',
  productType: 'nutrition-menopause',
  product: {
    148: 'nutrition-menopause',
    tourId: 148,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
  },
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
