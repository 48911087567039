
import { mapActions } from 'vuex';

import DisclaimerPrivacyTerms from '@/components/static/disclaimer-privacy-terms/disclaimer-privacy-terms.vue';
import PrivacyPolicyModal from '@/components/static/privacy-policy-and-terms-modal/privacy-policy-modal.vue';
import TermsModal from '@/components/static/privacy-policy-and-terms-modal/terms-modal.vue';

export default {
  components: {
    PrivacyPolicyModal,
    TermsModal,
    DisclaimerPrivacyTerms,
  },
  props: {
    titleHero: {
      type: String,
      default: null,
    },
    landingPage: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('quiz', ['pushQuizAnswerToTheStoreAction', 'setDefaultStateValue']),
    handleDefaultState(value) {
      this.pushQuizAnswerToTheStoreAction({ id: 'age', answer: value });
      this.setDefaultStateValue(value);
      this.$router.push('/quiz/start');
    },
    openTermsModal() {
      this.$modal.show('termsModal');
    },
    openPrivacyModal() {
      this.$modal.show('privacyPolicyModal');
    },
  },
};
