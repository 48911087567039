
import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';

export default {
  name: 'WebLogo',

  components: {
    RhLogo: () => import('@/components/ui/RhLogo/RhLogo.vue'),
    FitLogo: () => import('@/components/fitover40/FitLogo/FitLogo.vue'),
  },

  props: {
    brand: {
      type: String,
      default: PROJECT_BRANDS.reverse,
      validator: (value) => Object.values(PROJECT_BRANDS).includes(value),
    },
  },

  computed: {
    isF040Brand() {
      return this.brand === PROJECT_BRANDS.fitover40;
    },
  },
};
