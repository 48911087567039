import { functionsApi } from '@/modules/apis/functions-api';

export const upsellRepository = {
  async purchaseUpsell(payload) {
    try {
      const response = await functionsApi.post('/v2/upsells', payload);
      return response.data;
    } catch (error) {
      error.message = `Error in purchaseUpsell repository: ${error.message}`;
      error.payload = payload;
      throw error;
    }
  },
};
