/**
 * List of all components names
 * @return {String} that contain single component name based on action
 */

export default {
  // Common
  EMPTY_VALUE: '',

  // Payment flow
  PAYMENT_LOADER: 'payment-loader',
  PAYMENT_LOADER_SUCCESSFUL: 'payment-loader-successful',
  PAYMENT_LOADER_SUCCESSFUL_WITH_PHONE: 'payment-loader-successful-with-phone',
  PAYMENT_LOADER_UNSUCCESSFUL: 'payment-loader-unsuccessful',
  PAYMENT_UPSELL_LOADER_UNSUCCESSFUL: 'payment-upsell-loader-unsuccessful',
  PAYMENT_DOWNLOAD_APP_V2: 'payment-download-app-v2',

  // Upsell flow
  DONATE: 'donate',
  UPSELL: 'upsell',
  UPSELL_FAMILY: 'upsell-family',
  UPSELL_FAST_FOOD: 'upsell-fast-food',
  UPSELL_EMPTY_CART: 'upsell-empty-cart',
  UPSELL_SHIPPING: 'upsell-shipping',
  UPSELL_PREMIUM_CONTENT: 'upsell-premium-content',
  UPSELL_SWEET_TREATS: 'upsell-sweet-treats',
  UPSELL_12_MONTH: 'upsell-12-month',
  ORDER_SUMMARY: 'order-summary',

  // Global event names mapping
  upsellProducts: 'upsell',
  upsellShipping: 'upsell-shipping',
  upsellDonate: 'donate',
  upsellFastFoodMeal: 'upsell-fast-food',
  upsellFastFoodMealPilates: 'upsell-fast-food-pilates',
  upsellPremiumContent: 'upsell-premium-content',
  upsellAlcohol: 'upsell-alcohol',
  upsellDetox: 'upsell-detox',
  upsellSweetTreatsMeal: 'upsell-sweet-treats',
  upsell12Month: 'upsell-12-month',
  upsell12MonthLifetime: 'upsell-12-month',
  upsellDayMealPlan: 'upsell-day-meal-plan',
};
